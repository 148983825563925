export const OPEN_MARKET_PAGE_TITLE = 'OpenMarket';
export const openMarketNoAccessDescription =
  'Explore an underwriter’s catalog of all active Deal Roadshow-linked offerings on a clean, intuitive OpenMarket profile.';
export const openMarketNoAccessFeatures = [
  'Instantly discover available underwriter profiles on Finsight.com',
  'Explore live offerings across credit products, sectors and regions on a single profile',
  'Expedite access to relevant deal documents, call details and more on Deal Roadshow',
];
export const openMarketImageCaption =
  'Filter and explore across a range of offerings from underwriters through OpenMarket Profiles.';

import { useCallback, useEffect, useRef, useState } from 'react';
import useFetchCollection, { IFetchCallback, IFetchCollectionPayload } from '@/Framework/State/useFetchCollection';

export const useBufferedFetchCollection = <T>({
  initial,
  fetcher,
  payload,
  perPage,
}: {
  fetcher: IFetchCallback<T, IFetchCollectionPayload>,
  perPage: number,
  payload?: IFetchCollectionPayload,
  initial?: {
    collection: T[],
    totalCount: number,
  },
}) => {
  const isFirstRender = useRef(true);
  const [collection, setCollection] = useState<T[]>(initial?.collection ?? []);

  const fetchFunction: IFetchCallback<T, IFetchCollectionPayload> = useCallback(async (payload) => {
    if (isFirstRender.current) {
      isFirstRender.current = false;

      if (initial && initial.totalCount === initial.collection.length) {
        return initial;
      }
    }

    return fetcher({
      ...payload,
      page: payload.page,
    });
  }, []);

  const {
    totalCount,
    isFetching,
    collection: bufferCollection,
    paginate,
    page,
    debouncedSearch,
    debouncedSearchString,
    isInitialized,
  } = useFetchCollection(fetchFunction, payload, {
    perPage,
    totalCount: initial?.totalCount ?? 0,
  });

  const loadMore = () => {
    setCollection((prevState) => [...prevState, ...bufferCollection]);

    if (totalCount !== collection.length + bufferCollection.length) {
      paginate(page + 1);
    }
  };

  useEffect(() => {
    if (isInitialized && page === 1) {
      setCollection(bufferCollection);

      if (totalCount !== bufferCollection.length) {
        paginate(page + 1);
      }
    }
  }, [bufferCollection, isInitialized]);

  const isFirstPageFetching = page === 1 && isFetching;
  const isNextPageFetching = page !== 1 && isFetching;

  return {
    collection,
    isFetching,
    isFirstPageFetching,
    isNextPageFetching,
    totalCount,
    loadMore,
    debouncedSearch,
    debouncedSearchString,
  };
};

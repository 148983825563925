import React from 'react';
import { Icon, IconType } from '@dealroadshow/uikit';

import styles from './styles.scss';

const DesktopOnlyCaption = () => (
  <div className={ styles.desktopOnlyCaption }>
    <Icon type={ IconType.info } />
    Currently available on Desktop
  </div>
);

export default DesktopOnlyCaption;

import React from 'react';
import cn from 'classnames';

import styles from './recaptcha.scss';

interface IProps {
  className?: string,
}

// use when badge of recaptcha is hide
// doc: https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed
const ReCaptchaPolicy = ({ className }: IProps) => {
  return (
    <div className={ cn(styles.recaptchaPolicy, className) }>
      This site is protected by reCAPTCHA and the Google{ ' ' }
      <a href="https://policies.google.com/privacy">Privacy Policy</a> and{ ' ' }
      <a href="https://policies.google.com/terms">Terms of Service</a> apply.
    </div>
  );
};

export default ReCaptchaPolicy;

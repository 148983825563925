import { useEffect, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Button, ButtonVariantType, Input, Textarea } from '@dealroadshow/uikit';
import config from '@/Framework/config';
import FinalForm, { Field } from '@/Framework/UI/Organisms/FinalForm';
import { TFinalFormRenderProp } from '@/Framework/UI/Organisms/FinalForm/interfaces';
import EmailInput from '@/Framework/UI/Organisms/FinalForm/Fields/EmailInput';
import ReCaptchaPolicy from '@/Framework/UI/Molecules/Form/ReCaptcha/ReCaptchaPolicy';
import { useSessionContext } from '@/users/application/Session/SessionContext';
import validator from './validation';
import { IJoinWaitlistFormValues } from './interfaces';

import styles from './joinWaitlistModal.scss';

const JoinWaitlistFields: TFinalFormRenderProp<IJoinWaitlistFormValues> = ({ valid, form }) => {
  const recaptchaRef = useRef<ReCAPTCHA>();

  useEffect(() => {
    if (recaptchaRef.current) {
      form.change('recaptcha', recaptchaRef.current);
    }
  }, []);

  return (
    <>
      <div className={ styles.headerText }>
        <h1>Be the first to explore live offerings on OpenMarket</h1>
        <p className={ styles.modalDescription }>
          OpenMarket is currently in closed beta. Join the waitlist to receive a notification when it launches.
        </p>
      </div>
      <div className={ styles.nameInputs }>
        <Field
          name="firstName"
          type="text"
          label="First Name"
          placeholder="First Name"
          component={ Input }
          dataTest="firstNameInput"
        />
        <Field
          name="lastName"
          type="text"
          label="Last Name"
          placeholder="Last Name"
          component={ Input }
          dataTest="lastNameInput"
        />
      </div>
      <Field
        name="email"
        type="email"
        label="Corporate Email"
        placeholder="Corporate Email"
        component={ EmailInput }
        showIcon={ false }
        dataTest="emailInput"
      />
      <Field
        textareaClassName={ styles.textarea }
        label="Comment"
        placeholder="Please provide additional details if applicable..."
        name="comment"
        component={ Textarea }
        dataTest="commentTextarea"
        maxLength={ 1000 }
      />
      <Button
        dataTest="notifyMeButton"
        type="submit"
        disabled={ !valid }
        block
        variant={ ButtonVariantType.action }
      >
        Notify Me
      </Button>
      <div className={ styles.recaptchaContainer }>
        <ReCAPTCHA
          size="invisible"
          ref={ recaptchaRef }
          sitekey={ config.recaptcha.invisible.siteKey }
        />
        <ReCaptchaPolicy />
      </div>
    </>
  );
};

interface IProps {
  onSubmit: (values: IJoinWaitlistFormValues) => void,
}

const JoinWaitlistForm = ({ onSubmit }: IProps) => {
  const { currentUser } = useSessionContext();

  return (
    <FinalForm<IJoinWaitlistFormValues>
      name="notifyMeForm"
      dataTest="notifyMeForm"
      render={ JoinWaitlistFields }
      validate={ validator }
      initialValues={ {
        firstName: currentUser?.firstName ?? '',
        lastName: currentUser?.lastName ?? '',
        email: currentUser?.email ?? '',
        comment: '',
        recaptcha: null,
      } }
      onSubmit={ onSubmit }
    />
  );
};

export default JoinWaitlistForm;

import React from 'react';
import { StaticImageData } from 'next/image';
import cn from 'classnames';
import FeatureItem from './FeatureItem';
import LogInButton from '@/finsight/ui/common/components/LogInButton';
import UpgradePlanButton from '@/finsight/ui/common/components/UpgragePlanButton';
import Image from '@/Framework/Router/Next/Image';
import DesktopOnlyCaption from './DesktopOnlyCaption';

import styles from './styles.scss';

interface IProps {
  title: string,
  description: string,
  features: string[],
  image: StaticImageData | string,
  imageCaption?: string,
  isLoginButton?: boolean,
  isDoubleImage?: boolean,
  customButton?: React.ElementType,
  isDesktopOnly?: boolean,
}

const NoAccessOverlay = ({
  title,
  description,
  features,
  isLoginButton = false,
  image,
  imageCaption,
  isDoubleImage = false,
  customButton: CustomButton,
  isDesktopOnly = false,
}: IProps) => {
  const renderButton = () => {
    if (CustomButton) {
      return <CustomButton />;
    }
    if (isLoginButton) {
      return <LogInButton dataTest="noAccessLoginButton" withIcon={ false } className={ styles.actionButton } />;
    }
      return <UpgradePlanButton className={ styles.actionButton } />;
  };

  const getButton = (isDesktopOnly = false) => (
    <div className={ styles.buttonWrapper }>{ isDesktopOnly && <DesktopOnlyCaption /> }
      <div className={ cn({ [styles.mobileVisible]: isDesktopOnly }) }>{ renderButton() }</div>
    </div>
  );

  return (
    <div className={ styles.container } data-test="noAccessOverlay">
      <div className={ cn(styles.content, { [styles.isDoubleImage]: isDoubleImage }) }>
        <div>
          <h1>{ title }</h1>
          <div className={ styles.description }>{ description }</div>
          { features.map((item) => <FeatureItem key={ item } text={ item } />) }
          { getButton(isDesktopOnly) }
        </div>
        <div>
          <Image
            src={ image }
            width={ isDoubleImage ? 722 : 588 }
            height={ isDoubleImage ? 475 : 406 }
            alt={ title }
            priority
          />
          { imageCaption && <div className={ styles.caption }>{ imageCaption }</div> }
        </div>
      </div>
    </div>
  );
};

export default NoAccessOverlay;

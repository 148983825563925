import React from 'react';
import { Icon, IconType } from '@dealroadshow/uikit';

import styles from './styles.scss';

interface IProps {
  text: string,
}

const FeatureItem = ({ text }: IProps) => (
  <div className={ styles.featureItem }>
    <Icon
      type={ IconType.check }
      className={ styles.icon }
    />
    <div className={ styles.text }>{ text }</div>
  </div>
);

export default FeatureItem;

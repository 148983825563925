import React from 'react';
import cn from 'classnames';
import Title from '@/finsight/ui/common/components/Title';
import CheckUserPlanAccess from '@/finsight/ui/common/components/CheckUserPlanAccess';
import { UserPlanType } from '@/users/domain/UserPlan';

import pageHeaderWrapper from './pageHeaderWrapper.scss';

interface IPageHeaderWrapperProps {
  title: string | React.ReactNode,
  requiredUserPlan?: UserPlanType,
  className?: string,
  dataTest?: string,
  children?: React.ReactNode,
}

const PageHeaderWrapper = (
  {
   title = '',
   children,
   className,
   dataTest = 'pageHeaderTitle',
  requiredUserPlan,
  } : IPageHeaderWrapperProps,
) => {
  const headerWrapper = (
    <div className={ cn(pageHeaderWrapper.pageHeader, className) }>
      <Title
        isH1
        className={ pageHeaderWrapper.pageHeaderTitle }
        dataTest={ dataTest }
      >
        { title }
      </Title>
      { children && (
        <div className={ pageHeaderWrapper.pageHeaderButtons }>
          { children }
        </div>
      ) }
    </div>
  );

  return (
    requiredUserPlan ? (
      <CheckUserPlanAccess
        requiredUserPlan={ requiredUserPlan }
        fallback={ (
          <div className={ pageHeaderWrapper.mobileNoAccess }>
            { headerWrapper }
          </div>
        ) }
      >
        { headerWrapper }
      </CheckUserPlanAccess>
    ) : headerWrapper
  );
};

export default PageHeaderWrapper;

import { injectable, inject } from 'inversify';
import Request from '@/Framework/api/Rpc/Request';
import RpcDispatcher from '@/finsight/application/DI/Rpc/finsight-web/HttpDispatcher';
import { IProfile, IProfileTab, IProfileTabsFilters } from '@/openMarket/domain/vo/IProfile';
import { IFetchCollectionPayload, IFetchCollectionResponse } from '@/Framework/State/useFetchCollection';

@injectable()
class ProfileRepository {
  constructor(@inject(RpcDispatcher) private readonly rpc: typeof RpcDispatcher) {}

  getProfiles = async (payload: IFetchCollectionPayload): Promise<IFetchCollectionResponse<IProfile>> => {
    const request = new Request('finsight_web.open_market.get_profiles', payload);
    const response = await this.rpc.call(request);

    return response.getResult().payload;
  };

  getProfile = async ({ viewId, alias }: { viewId?: number, alias?: string }): Promise<IProfile> => {
    const request = new Request('finsight_web.open_market.get_profile', { viewId, alias });
    const response = await this.rpc.call(request);

    return response.getResult().payload;
  };

  getProfileTabs = async ({
    viewId,
    filters,
  }: {
    viewId: number,
    filters?: IProfileTabsFilters,
  }): Promise<IProfileTab[]> => {
    const request = new Request('finsight_web.open_market.get_profile_display_tabs', { viewId, filters });
    const response = await this.rpc.call(request);

    return response.getResult().payload;
  };
}

export default ProfileRepository;
